import 'whatwg-fetch'
import '@/styles/basicStyles'
import '@/styles/loading-ellipsis.css'
import RealVue from 'vue'
import SvgIcon from 'vue-svgicon'
import Constants from 'vue-constants'
import Toaster from '../../src/components/widgets/Toaster/toaster'
import Modal from 'vue-js-modal'
import Fragment from 'vue-fragment'
import objSet from 'set-value'
import getDot from '@/utils/getDotNotation'
import { getDisplayTimeBuilder } from '@/utils/formatters/timeBuilder'
import Router from 'vue-router'
import Vuex from 'vuex'
import routeToUrl from '@/utils/routeToUrlMapper'
import isEmpty from '@/utils/isEmpty'
import deepcopy from 'deepcopy'
import SimpleIcon from '@/components/widgets/SimpleIcon'
import ListPickInput from '@/components/inputs/ListPickInput'
import TextInput from '@/components/inputs/TextInput'
import ButtonInput from '@/components/inputs/ButtonInput'
import DropdownOptionsList from '@/components/inputs/DropdownOptionsList'
import StateBadge from '@/components/widgets/StateBadge'
import RoundToggle from '@/components/inputs/RoundToggleInput'
import isObject from 'isobject'
import * as apiActions from '@/api/actions'
// import Zendesk from "@dansmaculotte/vue-zendesk";
import * as styleConfiguration from '@/styles/config.sass'
import escapeHtml from '@/utils/escapeHtml'
import extendObject from '@/utils/extendObject'
import router from '@/router/router'
import vuetify from '@/plugins/vuetify'
import moment from 'moment-timezone'
import { DISPLAY_DATE_FORMAT } from '@/constants'
import vLoading from '@/directives/loading'
import axios from 'axios'
import {
  axiosErrorHandler,
  axiosResponseHandler,
} from '@/api/axiosInterceptors'
export { vuetify, router }
import store from '@/store'
import { injectWordWrapTag } from '@/utils/formatters/injectWordWrapTag'
import {
  redirectToOnboarding,
  checkPermissionValidForOnboarding,
} from '@/router/guard.ts'
import { BasicTable, BasicButton, FrankieIcon } from '@frankieone/ui-components'
import '@frankieone/ui-components/dist/index.css'
import { mkHtmlSanitiser } from '@frankieone/shared/dist/utils/sanitiser'
import VueGtag from 'vue-gtag'
import singleSpaVue from 'single-spa-vue'

export function makeGlobalMixin(allData, isDev) {
  if (document && !document.title) document.title = 'Frankie Financial'

  allData = allData || {}
  allData['timezone'] = allData.timezone || 'UTC'
  const todayObject = moment.tz(allData.timezone)
  return {
    computed: {
      $style: () => styleConfiguration,
      isVuetifyApp: () => false,
      pageData: () => allData,
      isDevelopment: () => isDev,
      todayObj: () => todayObject,
      canManageUser() {
        if (!this.pageData.hasOwnProperty('user')) {
          return false
        }

        return this.pageData.user.canManageUsers || false
      },
    },
    methods: {
      ...apiActions,
      injectWordWrapTag,
      escapeHtml,
      extendObject,
      sanitiseHtml(value) {
        const sanitiser = mkHtmlSanitiser({ disallowedTagsMode: 'discard' })
        const sanitise = sanitiser(value || '')
        return sanitise.replace(/&amp;/g, '&')
      },
      routeToUrl,
      getDisplayTime: getDisplayTimeBuilder(allData.timezone),
      makeAxiosAborter: () => axios.CancelToken.source(),
      urlIf(value) {
        value.trim()
        return value.slice(0, 4) === 'http' || value.slice(0, 5) === 'https'
      },
      parseLocalizedDate(localDateString) {
        return moment.tz(localDateString, this.pageData.timezone).utc()
      },
      parseUtcDate(utcDateString, skipTimezone) {
        const preDefinedFormats = [
          {
            format: 'DD/MM/YYYY',
            regex: /^\d{2}\/\d{2}\/\d{4}$/,
          },
          {
            format: 'DD-MM-YYYY',
            regex: /^\d{2}-\d{2}-\d{4}$/,
          },
        ]

        // Checking for predefined formats
        for (const { format, regex } of preDefinedFormats) {
          if (
            regex.test(utcDateString) &&
            moment.utc(utcDateString, format).isValid()
          ) {
            return skipTimezone
              ? moment.utc(utcDateString, format)
              : moment.utc(utcDateString, format).tz(this.pageData.timezone)
          }
        }

        // Default case
        const parsedDate = moment.utc(utcDateString)
        if (parsedDate.isValid()) {
          return skipTimezone
            ? parsedDate
            : parsedDate.tz(this.pageData.timezone)
        }

        return ''
      },
      momentJs: moment,
      isEmpty,
      isObject,
      copyObject: deepcopy,
      setField(path, v) {
        const value = this.value
        objSet(value, path, v)
        this.$emit('input', value)
      },
      getField(path, where) {
        where = where || this.value
        return getDot(path, where)
      },
      getFeatureConfig(feature) {
        const features = this.pageData?.features || {}

        return features?.[feature] || null
      },
    },
  }
}

window.moment = moment
export default function init(injectedData: any, options: any = {}) {
  const {
    includeRouter,
    includeStore,
    dataFromElementArguments,
    useThisVue,
    rootComponent,
    isVuetifyApp,
    mockDataOnly,
    isPublic,
  } = Object.assign(
    {
      dataFromElementArguments: [],
      isVuetifyApp: true,
      includeRouter: true,
      includeStore: true,
      mockDataOnly: false,
      isPublic: false,
    },
    options,
  )
  if (!isPublic) {
    axios.interceptors.response.use(axiosResponseHandler, axiosErrorHandler)
  }
  const Vue = useThisVue || RealVue

  Vue.config.devtools = true
  Vue.prototype.$ev = new Vue()

  const windowUrl = window.location.href
  const isStaging = windowUrl.includes('latest')
  const isDev = process.env.NODE_ENV === 'development'
  const isTest = process.env.NODE_ENV === 'test'
  const isProd = process.env.NODE_ENV === 'production'

  Vue.mixin(makeGlobalMixin(injectedData, isDev))
  Vue.mixin({ computed: { isVuetifyApp: () => isVuetifyApp } })
  // Add timezone from 'allData' in to function getDisplayTimep
  Vue.filter('displayDate', (dateString, format, readFormat) => {
    if (!dateString && !moment(dateString).isValid()) return ''
    let obj
    if (readFormat) {
      obj = moment(dateString, readFormat)
    } else {
      obj = moment(dateString)
    }
    return obj.format(format || DISPLAY_DATE_FORMAT)
  })

  Vue.component('basic-button', BasicButton)
  Vue.component('simple-icon', SimpleIcon)
  Vue.component('list-pick-input', ListPickInput)
  Vue.component('button-input', ButtonInput)
  Vue.component('dropdown-options-list', DropdownOptionsList)
  Vue.component('text-input', TextInput)
  Vue.component('state-badge', StateBadge)
  Vue.component('round-toggle', RoundToggle)
  Vue.component('frankie-icon', FrankieIcon)
  Vue.component('basic-table', BasicTable)

  Vue.directive('loading', vLoading)

  Vue.use(Fragment.Plugin)
  Vue.use(Modal, {
    dynamic: true,
    injectModalsContainer: true,
  })
  Vue.use(Constants)
  Vue.use(SvgIcon, {
    tagName: 'svgicon',
  })
  Vue.use(Toaster)
  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.

    // TODO: Perform any custom logic or log to server
    if (!isTest) {
      console.error(err)
      console.log(info)
      console.dir(vm)
    }
  }

  if (rootComponent) {
    console.log('FF-v1.14.2')
    const extraOptions: any = {}
    if (includeRouter) {
      Vue.use(Router)
      const routerInstance = router({ pageData: injectedData })
      extraOptions.router = routerInstance

      if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
        Vue.use(
          VueGtag,
          {
            config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
          },
          routerInstance,
        )
      }
    }
    if (includeStore) {
      Vue.use(Vuex)
      extraOptions.store = store({ pageData: injectedData })
    }
    if (isVuetifyApp) extraOptions.vuetify = vuetify
    if (checkPermissionValidForOnboarding(isPublic, injectedData))
      return redirectToOnboarding()
    else {
      // to run vue without react-app and root
      if (!isProd && !process.env.VUE_APP_SINGLE_SPA) {
        new Vue({
          ...extraOptions,
          render: h => h(rootComponent),
        }).$mount('#app')
      }
      return singleSpaVue({
        Vue,
        appOptions: {
          render(h) {
            return h(rootComponent)
          },
          ...extraOptions,
        },
      })
    }
  }

  return Vue
}
