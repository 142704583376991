import { getPageData } from "@/utils/pageDataStorage";
import { isFeatureEnabled } from "@/utils/featureflag";

export const TXN_PERMISSIONS = {
  AML: "ffportal_applicant_fetch_aml_transaction_data",
  FRAUD: "ffportal_applicant_fetch_fraud_transaction_data",
  CUSTOMER: "ffportal_applicant_fetch_device_transaction_data"
};

export const isTxnPermission = (permission: string): boolean => {
  return !!/(ffportal_applicant_fetch_)(.*)(_transaction_data)/g.test(permission);
};

export const filterTxnPermissions = (permissions: string[]): string[] => {
  return permissions.filter(permission => isTxnPermission(permission));
};

export const hasTxnFeature = (): boolean => {
  return isFeatureEnabled("transaction-monitoring") || !!getPageData()?.features?.transactionMonitoring;
};

export const hasTxnPermissionAML = (): boolean => {
  return !!getPageData()?.permissions?.includes(TXN_PERMISSIONS.AML);
};

export const hasTxnPermissionFraud = (): boolean => {
  return !!getPageData()?.permissions?.includes(TXN_PERMISSIONS.FRAUD);
};

export const hasTxnPermissionCustomer = (): boolean => {
  return !!getPageData()?.permissions?.includes(TXN_PERMISSIONS.CUSTOMER);
};

export const hasAnyTxnPermissions = (permissions: string[]): boolean => {
  return !!filterTxnPermissions(permissions).length;
};
