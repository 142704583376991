<template lang="pug">
    header#main-top-header
       
        nav.modules.navigation(v-if='!isAnonymous')
            .logo.module.center-stuff(@click="redirectToHome")
                frankie-icon( name="frankie-logo" color='ORIGINAL' :size="{ width: 100, height: 16 }" :click-area-ratio="1" :click-area-min-size="0" clickable)
            router-link.module.link.center-stuff(
              v-for="{permission, link, slug, text, icon, outlined, shaded, classes} in links"
              v-if="hasPermission(permission)"
              :key="slug"
              :class="[{active: isPathActive(link)}, {outline: outlined}, {shaded}, classes]"
              :to="link"
              :data-qa="`access link ${slug}`" )
                simple-icon.icon( :icon="icon" :overrideColor="isPathActive(link) ? (outlined ? COLORS.WHITE : COLORS.BLUE) : (outlined ? COLORS.WHITE : COLORS.GRAY)" size="16px" padding="1px" )
                span.f-listButton.align-center {{ text }}
        .about(v-if="isAnonymous")
            a(href='https://www.frankiefinancial.com' :style="{color: 'black'}") About Frankie
        .parent-child(v-if="!isAnonymous && hasPermission('ffportal_user_child_view')" :class="{ 'child-selected': selectedChildId }" )
            children-tree-select( v-loading.small="isChildIdSelectorBusy" :data='customerChildData' :selectedIds="selectedIds" @input="selectedIds = $event"  v-if='currentCustomer' :disabled="isChildIdSelectorBusy" )
        .user(v-if="!isAnonymous")
            user-menu(:usernameDisplayIcon="pageData && pageData.user && usernameDisplayIcon(pageData.user.realname)")
        .loading( :class="{ shownLoadingAnimation: loading }" data-qa="new-profile-submission-loading")
            .overlay-background
            .frankie-logo
                img.logo-animation(src="../../assets/svg/f-loading.svg")
                .extra-text.f-body-bold(v-if="loadingExtraText") {{loadingExtraText}}
</template>

<style lang="sass" scoped>
@import '~@/styles/config'
$padding: $LENGTH__SM_PADDING
#main-top-header
    background: white
    position: relative
.parent-child
    z-index: 11
    margin: 0 12px 0 0
header
    display: flex
    align-items: center
    height: $LENGTH__HEADER_HEIGHT
    padding: 0 32px
    justify-content: space-between
    box-shadow: 0px 1px 2px $COLOR__SHADOW_3
    .logo
        display: flex
        flex: 0 0 auto
        cursor: pointer
        padding: 5px 8px 8px 8px !important
        @media (max-width: 1440px)
            padding-right: 0
            width: 100px
    .modules
        flex: 1 1 auto
        display: flex
        gap: 8px
        .module
            padding: 8px 12px
            @media (max-width: 1440px)
                margin: 0
            &.outline
                border: 1px solid #000000
                -webkit-border-radius: 4px
                -moz-border-radius: 4px
                border-radius: 4px
                color: $COLOR__TEXT

                &.active
                    border-color: transparent
                    -webkit-border-radius: 0
                    -moz-border-radius: 0
                    border-radius: 0

            .icon
                margin-right: 10px

            span
                height: 20px
                font-size: $SIZE__BASE_SM

    .user
        flex: 0 0 auto

    .right_menu

        padding-right: 2rem

        .active span
            color: $COLOR__ACCENT
        .group
            display: flex
            align-items: center
            padding: 7px 10px
            border-radius: 5px
        .active .group
            background-color: $COLOR__LIGHTGREY
            font-weight: 600
        span
            color: $COLOR__TOP_MENU_GREY
        .icon
            margin-right: $LENGTH__SM_PADDING

    .about
        padding-right: 6rem
        color: black

    .loading
        height: 100%
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        background: none
        position: fixed
        top: 0
        left: 0
        z-index: 99999
        opacity: 0
        transition-property: transform, opacity
        transition-duration: .2s
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55)
        // box-shadow: 0 0 3px 0px #000000
        transform: scale(0)
    .shownLoadingAnimation
        transform: scale(1)
        opacity: 1

    @keyframes
        100%
            stroke-dashoffset: 0
.overlay-background
    background-color: white
    opacity: 0.7
    position: absolute
    z-index: 1
    height: 100%
    width: 100%
.frankie-logo
    position: absolute
    z-index: 999999
    display: flex
    align-items: center
    flex-direction: column
.extra-text
    color: $COLOR__DARK_NAVY
    margin-top: 20px
.new-profile-button
    background-color: $COLOR_PRIMARY_BLUE
    border-color: $COLOR_PRIMARY_BLUE !important
    border-radius: 4px !important
    color: $COLOR__WHITE !important
    &:hover
      background-color: $COLOR_PRIMARY_BLUE_900 !important
    &:active
      background-color: $COLOR_PRIMARY_BLUE_900 !important
      outline: 3px solid $COLOR_PRIMARY_BLUE_200 !important
    .active
</style>
<script>
import {
  hasTxnFeature,
  TXN_PERMISSIONS,
} from '@/pages/applicantDetails/transactions/TransactionsPermissionHelpers'
import UserMenu from './UserMenu'
import routeToUrlMapper from '@/utils/routeToUrlMapper'
import '@/components/icons/logo'
import SimpleIcon from '@/components/widgets/SimpleIcon'
import STYLINGMIXIN from '@/components/widgets/WidgetColorMappings'
import styles from '@/styles/config.sass'
import ChildrenTreeSelect from '@/components/smart-inputs/ChildrenTreeSelect.vue'
import { mapActions } from 'vuex'
import { mkUserLabel } from '@/utils/formatters/mkUserLabel.js'
export default {
  constants: {
    COLORS: {
      ACCENT: STYLINGMIXIN.constants.COLORS.ACCENT,
      BLACK: STYLINGMIXIN.constants.COLORS.BLACK,
      GRAY: styles.COLOR__TOP_MENU_GREY,
      COLOR__BLUE: styles.COLOR__BLUE,
      WHITE: styles.COLOR__WHITE,
      BLUE: styles.COLOR_PRIMARY_BLUE,
    },
    links: [
      {
        permission: ['ffportal_onboarding_index'],
        link: routeToUrlMapper('ffportal_onboarding'),
        slug: 'onboarding',
        text: 'Onboarding',
        icon: 'onboarding',
      },
      {
        permission: ['ffportal_monitoring_index'],
        link: routeToUrlMapper('ffportal_monitoring'),
        slug: 'monitoring',
        text: 'Monitoring',
        icon: 'onboarding_check',
      },
      {
        permission: hasTxnFeature()
          ? [
              TXN_PERMISSIONS.AML,
              TXN_PERMISSIONS.FRAUD,
              TXN_PERMISSIONS.CUSTOMER,
            ]
          : [],
        link: routeToUrlMapper('ffportal_transactions'),
        slug: 'transactions',
        text: 'Fraud/AML',
        icon: 'transaction-record',
      },
      {
        permission: ['ffportal_onboarding_index'],
        link: routeToUrlMapper('ffportal_dashboard'),
        slug: 'dashboard',
        text: 'Dashboard',
        icon: 'dashboard',
      },
      {
        permission: ['ffportal_entity_blacklist'],
        link: routeToUrlMapper('ffportal_blocklist'),
        slug: 'blocklist',
        text: 'Blocklist',
        icon: 'not-allow',
      },
      {
        permission: ['ffportal_applicant_new'],
        link: routeToUrlMapper('ffportal_applicant_new', 'onboarding'),
        slug: 'new_profile',
        text: 'New Profile',
        icon: 'add',
        outlined: true,
        classes: 'new-profile-button',
      },
    ],
  },
  components: {
    UserMenu,
    SimpleIcon,
    ChildrenTreeSelect,
  },
  data: () => ({
    loading: false,
    loadingExtraText: null,
  }),
  computed: {
    isAnonymous() {
      return this.$route.meta.isPublicPage
    },
    userPermissions() {
      return !this.isAnonymous && this.pageData && this.pageData.permissions
    },
    activeModule() {
      return this.pageData.path
    },
    childCustomers() {
      return (
        (!this.isAnonymous &&
          this.pageData.organization &&
          this.pageData.organization.children) ||
        []
      )
    },
    currentCustomer() {
      return this.pageData?.organization
    },
    isShowParentChild() {
      return this.childCustomers && this.childCustomers.length !== 0
    },
    customerChildData() {
      const transformData = (data, isParent) => {
        const item = {}
        item.title = data.name
        item.id = null
        item.isParent = isParent
        if (isParent) {
          item.id = data.customerId
        } else {
          item.id = data.customerChildId
        }
        //falback for the strange DB case when isRoot field === FALSE for the parent record
        item.id = item.id || data.customerId || data.customerChildId

        item.tick = this.selectedIds.includes(item.id)
        return item
      }
      // FIX: get parent customer is not correct
      // only show current customer or childs customer in the dropdown
      // isRoot is use for detecting organisation is a parent organisation or a child organisation
      const current = transformData(
        this.currentCustomer,
        this.currentCustomer.isRoot,
      )

      const childs = this.childCustomers.map(child => transformData(child))
      return { ...current, items: [...childs] }
    },
    selectedChildId() {
      return this.$store.state.system.selectedChildId
    },
    selectedIds: {
      set(array) {
        if (array.length > 0) this.viewAsCustomerId({ id: array[0] })
      },
      get() {
        const selectedId = this.$store.getters.isViewingAsWhichCustomerId
        return [selectedId]
      },
    },
    isChildIdSelectorBusy() {
      return this.$store.state.system.isChildSelectionBusy
    },
    logoIcon() {
      return logoIcon
    },
  },
  methods: {
    ...mapActions(['viewAsCustomerId']),
    usernameDisplayIcon(realName) {
      return mkUserLabel(realName)
    },
    isPathActive(path) {
      return this.$route.matched.some(({ regex }) => path.match(regex))
    },
    hasPermission(which) {
      return this.userPermissions?.some(permission =>
        which.includes(permission),
      )
    },
    routeToUrlMapper(route, extra) {
      return routeToUrlMapper(route, extra)
    },
    redirectToHome() {
      window.location.href = '/'
    },
  },
  created() {
    this.$ev.$on('startLoading', extraText => {
      this.loading = true
      this.loadingExtraText = extraText
    })
    this.$ev.$on('stopLoading', () => {
      this.loading = false
      this.loadingExtraText = null
    })
  },
}
</script>
